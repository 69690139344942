<template>
  <div>
    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("Exam name") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("Exam date") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("Exam ID") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("Exam duration") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("Class") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("Subject") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("Class") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("Subject") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("Academic Year") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("Semester - Term ") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("Accessibility status") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("Duration status") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("Publishing type") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("Grating type") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("General status ") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("Time-based status") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("Occurrence status") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("Exam type") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
    </div>

    <div class="vx-row mb-6">
      <vs-divider />
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("students to attend") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("Questions") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t(" Question - auto grading") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full mb-2">
        <strong>{{ $t("points scale : 100 points") }}:</strong>
      </div>
      <div class="vx-col md:w-1/4 sm:w-full w-full">
        <div>!!</div>
      </div>
    </div>

    <div class="vx-row mb-6">
      <vs-divider />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
</style>